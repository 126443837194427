/**
 * Application entry point.
 *
 * This file includes `import`'s for all the components/directives used
 * by the HTML page and the controller to provide the configuration.
 */
import './sass/desktop.scss';
import './sass/vars_desktop.scss';
import './sass/desktop.scss';

import angular from 'angular';
import { isEventUsinCtrlKey } from 'ngeo/utils.js';
import gmfControllersAbstractDesktopController, {
  AbstractDesktopController,
} from 'gmf/controllers/AbstractDesktopController.js';
import geocommunesBase, { init } from '../geocommunesmodule.js';
import gmfLidarprofileModule from 'gmf/lidarprofile/module.js';
import ngeoMiscToolActivate from 'ngeo/misc/ToolActivate.js';
import ngeoStreetviewModule from 'ngeo/streetview/module';

if (!window.requestAnimationFrame) {
  alert(
    'Your browser is not supported, please update it or use another one. You will be redirected.\n\n' +
      "Votre navigateur n'est pas supporté, veuillez le mettre à jour ou en utiliser un autre. " +
      'Vous allez être redirigé.\n\n' +
      'Ihr Browser wird nicht unterstützt, bitte aktualisieren Sie ihn oder verwenden Sie einen anderen. ' +
      'Sie werden weitergeleitet.'
  );
  window.location.href = 'https://geomapfish.org/';
}

/**
 * @param {angular.Scope} $scope Scope.
 * @param {angular.$injector} $injector Main injector.
 * @constructor
 * @extends {gmf.controllers.AbstractDesktopController}
 * @ngInject
 * @export
 * @private
 */
class Controller extends AbstractDesktopController {
  /**
   * @param {angular.IScope} $scope Scope.
   * @param {angular.auto.IInjectorService} $injector Main injector.
   * @param {string} geocommuneCss
   * @ngInject
   */
  constructor($scope, $injector, geocommuneOptions) {
    super($scope, $injector);
    init($injector, geocommuneOptions);

    document.getElementById('clientUrl').href = geocommuneOptions.clientUrl;

    /**
     * @type {boolean}
     */
    this.drawLidarprofilePanelActive = false;

    const drawLidarprofilePanelActive = new ngeoMiscToolActivate(this, 'drawLidarprofilePanelActive');
    this.ngeoToolActivateMgr.registerTool('mapTools', drawLidarprofilePanelActive, false);
  }

  /**
   * @param {JQueryEventObject} event keydown event.
   */
  onKeydown(event) {
    if (event && isEventUsinCtrlKey(event) && event.key === 'p') {
      this.printPanelActive = true;
      event.preventDefault();
    }
  }
}

const geocommunesModule = angular.module('Appdesktop', [
  geocommunesBase.name,
  gmfControllersAbstractDesktopController.name,
  gmfLidarprofileModule.name,
  ngeoStreetviewModule.name,
]);

geocommunesModule.value('gmfContextualdatacontentTemplateUrl', 'gmf/contextualdata');
geocommunesModule.run(
  /**
   * @ngInject
   * @param {angular.ITemplateCacheService} $templateCache
   */
  ($templateCache) => {
    // @ts-ignore: webpack
    $templateCache.put('gmf/contextualdata', require('./contextualdata.html'));
  }
);

geocommunesModule.controller('DesktopController', Controller);

export default geocommunesModule;
